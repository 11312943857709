import blogpoststyle from "./blogpoststyle.css";

import moment from "moment";
import "moment/locale/nb";
import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
moment.locale("nb");

export default function BlogPost({ props }) {
  const [blogPost, setBlogPost] = React.useState();
  const [blogPosts, setBlogPosts] = React.useState();

  const params = useParams();

  const fetchBlogPost = async () => {
    const res = await fetch(
      process.env.REACT_APP_STRAPI_URL +
        "/api/newsposts?populate=*&filters[slug][$eq]=" +
        params.slug
    );

    const home = await res.json();
    console.log(home);

    setBlogPost(home.data[0]);
  };

  const fetchBlogPosts = async () => {
    const res = await fetch(
      process.env.REACT_APP_STRAPI_URL +
        "/api/newsposts?populate=*&sort[0]=publishedAt%3Adesc&pagination[page]=1&pagination[pageSize]=5"
    );

    const home = await res.json();
    console.log(home);

    setBlogPosts(home.data);
  };

  React.useEffect(() => {
    fetchBlogPost();
    fetchBlogPosts();
  }, []);
  return (
    <>
      <div id="wrapper">
        {/*<div id="preloader">
          <div className="preloader1"></div>
        </div>*/}
        <Header />
        <>
          <div className="no-bottom no-top" id="content">
            <div id="top" />
            {/* section begin */}
            <section
              id="subheader"
              className="text-light"
              style={{ backgroundImage: "url(/images-dj/background/t5.jpg)" }}
              //data-bgimage="url(/images-dj/background/t5.jpg) bottom"
            >
              <div className="center-y relative text-center">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <h1>{blogPost?.attributes.title}</h1>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
            </section>
            {/* section close */}
            {/* section begin */}
            <section aria-label="section">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="blog-read">
                      <div className="post-text">
                        <ReactMarkdown style={blogpoststyle}>
                          {blogPost?.attributes.body}
                        </ReactMarkdown>
                        <span className="post-date">
                          {moment(blogPost?.attributes.publishedAt).format(
                            "DD MMM YYYY"
                          )}
                        </span>
                        {/*<span className="post-comment">1</span>
                        <span className="post-like">181</span>*/}
                      </div>
                    </div>
                    <div className="spacer-single" />
                    {/*<div id="blog-comment">
                      <h4>Comments (5)</h4>
                      <div className="spacer-half" />
                      <ol>
                        <li>
                          <div className="avatar">
                            <img src="images-dj/misc/avatar-2.jpg" alt="" />
                          </div>
                          <div className="comment-info">
                            <span className="c_name">Merrill Rayos</span>
                            <span className="c_date id-color">
                              15 January 2020
                            </span>
                            <span className="c_reply">
                              <a href="#">Reply</a>
                            </span>
                            <div className="clearfix" />
                          </div>
                          <div className="comment">
                            Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium doloremque laudantium, totam
                            rem aperiam, eaque ipsa quae ab illo inventore
                            veritatis et quasi architecto beatae vitae dicta
                            sunt explicabo.
                          </div>
                          <ol>
                            <li>
                              <div className="avatar">
                                <img src="images-dj/misc/avatar-2.jpg" alt="" />
                              </div>
                              <div className="comment-info">
                                <span className="c_name">
                                  Jackqueline Sprang
                                </span>
                                <span className="c_date id-color">
                                  15 January 2020
                                </span>
                                <span className="c_reply">
                                  <a href="#">Reply</a>
                                </span>
                                <div className="clearfix" />
                              </div>
                              <div className="comment">
                                Sed ut perspiciatis unde omnis iste natus error
                                sit voluptatem accusantium doloremque
                                laudantium, totam rem aperiam, eaque ipsa quae
                                ab illo inventore veritatis et quasi architecto
                                beatae vitae dicta sunt explicabo.
                              </div>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <div className="avatar">
                            <img src="images-dj/misc/avatar-2.jpg" alt="" />
                          </div>
                          <div className="comment-info">
                            <span className="c_name">Sanford Crowley</span>
                            <span className="c_date id-color">
                              15 January 2020
                            </span>
                            <span className="c_reply">
                              <a href="#">Reply</a>
                            </span>
                            <div className="clearfix" />
                          </div>
                          <div className="comment">
                            Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium doloremque laudantium, totam
                            rem aperiam, eaque ipsa quae ab illo inventore
                            veritatis et quasi architecto beatae vitae dicta
                            sunt explicabo.
                          </div>
                          <ol>
                            <li>
                              <div className="avatar">
                                <img src="images-dj/misc/avatar-2.jpg" alt="" />
                              </div>
                              <div className="comment-info">
                                <span className="c_name">Lyndon Pocekay</span>
                                <span className="c_date id-color">
                                  15 January 2020
                                </span>
                                <span className="c_reply">
                                  <a href="#">Reply</a>
                                </span>
                                <div className="clearfix" />
                              </div>
                              <div className="comment">
                                Sed ut perspiciatis unde omnis iste natus error
                                sit voluptatem accusantium doloremque
                                laudantium, totam rem aperiam, eaque ipsa quae
                                ab illo inventore veritatis et quasi architecto
                                beatae vitae dicta sunt explicabo.
                              </div>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <div className="avatar">
                            <img src="images-dj/misc/avatar-2.jpg" alt="" />
                          </div>
                          <div className="comment-info">
                            <span className="c_name">Aleen Crigger</span>
                            <span className="c_date id-color">
                              15 January 2020
                            </span>
                            <span className="c_reply">
                              <a href="#">Reply</a>
                            </span>
                            <div className="clearfix" />
                          </div>
                          <div className="comment">
                            Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium doloremque laudantium, totam
                            rem aperiam, eaque ipsa quae ab illo inventore
                            veritatis et quasi architecto beatae vitae dicta
                            sunt explicabo.
                          </div>
                        </li>
                      </ol>
                      <div className="spacer-single" />
                      <div id="comment-form-wrapper">
                        <h4>Leave a Comment</h4>
                        <div className="comment_form_holder">
                          <form
                            id="contact_form"
                            name="form1"
                            className="form-border"
                            method="post"
                            action="#"
                          >
                            <label>Name</label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                            />
                            <label>
                              Email <span className="req">*</span>
                            </label>
                            <input
                              type="text"
                              name="email"
                              id="email"
                              className="form-control"
                            />
                            <div id="error_email" className="error">
                              Please check your email
                            </div>
                            <label>
                              Message <span className="req">*</span>
                            </label>
                            <textarea
                              cols={10}
                              rows={10}
                              name="message"
                              id="message"
                              className="form-control"
                              defaultValue={""}
                            />
                            <div id="error_message" className="error">
                              Please check your message
                            </div>
                            <div id="mail_success" className="success">
                              Thank you. Your message has been sent.
                            </div>
                            <div id="mail_failed" className="error">
                              Error, email not sent
                            </div>
                            <p id="btnsubmit">
                              <input
                                type="submit"
                                id="send"
                                defaultValue="Send"
                                className="btn btn-main"
                              />
                            </p>
                          </form>
                        </div>
                      </div>
                    </div>*/}
                  </div>
                  <div id="sidebar" className="col-md-4">
                    {/*<div className="de-box widget">
                      <h4>Share With Friends</h4>
                      <div className="small-border" />
                      <div className="de-color-icons">
                        <span>
                          <i className="fa fa-twitter fa-lg" />
                        </span>
                        <span>
                          <i className="fa fa-facebook fa-lg" />
                        </span>
                        <span>
                          <i className="fa fa-reddit fa-lg" />
                        </span>
                        <span>
                          <i className="fa fa-linkedin fa-lg" />
                        </span>
                        <span>
                          <i className="fa fa-pinterest fa-lg" />
                        </span>
                        <span>
                          <i className="fa fa-stumbleupon fa-lg" />
                        </span>
                        <span>
                          <i className="fa fa-delicious fa-lg" />
                        </span>
                        <span>
                          <i className="fa fa-envelope fa-lg" />
                        </span>
                      </div>
                    </div>*/}
                    <div className="de-box widget widget-post">
                      <h4>Siste innlegg</h4>
                      <div className="small-border" />
                      <ul>
                        {blogPosts?.map((data, index) => {
                          return (
                            <>
                              <li key={index}>
                                <span className="date">
                                  {moment(data.attributes.publishedAt).format(
                                    "DD MMM"
                                  )}
                                </span>
                                <a href={"/post/" + data.attributes.slug}>
                                  {data.attributes.title}
                                </a>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                    {/*<div className="de-box widget widget-text">
                      <h4>About Us</h4>
                      <div className="small-border" />
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt explicabo. Nemo
                      enim ipsam voluptatem quia voluptas sit aspernatur aut
                      odit aut fugit, sed quia consequuntur magni
                    </div>
                    <div className="de-box widget widget_tags">
                      <h4>Tags</h4>
                      <div className="small-border" />
                      <ul>
                        <li>
                          <a href="#link">Art</a>
                        </li>
                        <li>
                          <a href="#link">Application</a>
                        </li>
                        <li>
                          <a href="#link">Design</a>
                        </li>
                        <li>
                          <a href="#link">Entertainment</a>
                        </li>
                        <li>
                          <a href="#link">Internet</a>
                        </li>
                        <li>
                          <a href="#link">Marketing</a>
                        </li>
                        <li>
                          <a href="#link">Multipurpose</a>
                        </li>
                        <li>
                          <a href="#link">Music</a>
                        </li>
                        <li>
                          <a href="#link">Print</a>
                        </li>
                        <li>
                          <a href="#link">Programming</a>
                        </li>
                        <li>
                          <a href="#link">Responsive</a>
                        </li>
                        <li>
                          <a href="#link">Website</a>
                        </li>
                      </ul>
                </div>*/}
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* content close */}
          <a href="#" id="back-to-top" />
        </>
        <Footer />
      </div>
    </>
  );
}
