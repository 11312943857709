export default function HeadCarousel({ data }) {
  let carouseldata = data?.data?.attributes?.HeaderCarousel;
  return (
    <section
      id="de-carousel"
      className="no-top no-bottom carousel slide carousel-fade shadow-2-strong"
      data-mdb-ride="carousel"
    >
      {/* Indicators */}
      <ol className="carousel-indicators">
        <li
          data-mdb-target="#de-carousel"
          data-mdb-slide-to={0}
          className="active"
        />
        <li data-mdb-target="#de-carousel" data-mdb-slide-to={1} />
        <li data-mdb-target="#de-carousel" data-mdb-slide-to={2} />
      </ol>
      {/* Inner */}
      <div className="carousel-inner">
        {/* Single item */}
        {carouseldata?.map((data, index) => {
          var imageUrl = data?.image?.data?.attributes?.url;
          let classname;
          if (index === 0) {
            classname = "carousel-item active";
          } else {
            classname = "carousel-item";
          }
          return (
            <div
              key={index}
              className={classname}
              data-bgimage={"url(" + imageUrl + ")"}
              style={{
                background: "url(" + imageUrl + ")",
              }}
            >
              <div className="mask">
                <div className="d-flex justify-content-center align-items-center h-100">
                  <div className="container text-white text-center">
                    <div className="row">
                      <div className="col-md-12">
                        <h1 className="ultra-big mb-3 wow fadeInUp">
                          {data?.title.split(" ")[0]}
                          <br />
                          <span className="id-color">
                            {data?.title.split(" ")[1]}
                          </span>
                        </h1>
                        <div className="col-md-6 offset-md-3">
                          <p className="lead wow fadeInUp" data-wow-delay=".3s">
                            {data?.description}
                          </p>
                        </div>{" "}
                        <div className="spacer-10" />
                        <a
                          href="https://dmproduksjoner.hoopla.no/event/3495120335"
                          target="_blank"
                          rel="noreferrer"
                          className="btn-main wow fadeInUp"
                          data-wow-delay=".6s"
                        >
                          Kjøp Billett
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {/* Single item */}
        {/*<div
          className="carousel-item"
          data-bgimage="url(images-dj/slider/2.jpg)"
        >
          <div className="mask">
            <div className="d-flex justify-content-center align-items-center h-100 wow f">
              <div className="container text-white text-center">
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="ultra-big mb-3 wow fadeInUp">
                      5 Days
                      <br />
                      <span className="id-color">Party</span>
                    </h1>
                    <div className="col-md-6 offset-md-3">
                      <p className="lead wow fadeInUp" data-wow-delay=".3s">
                        The biggest music event in decades. Attended by more
                        than 100 musicians from all over the world. Get your
                        ticket now!
                      </p>
                    </div>{" "}
                    <div className="spacer-10" />
                    <a
                      href="#section-artists"
                      className="btn-main wow fadeInUp"
                      data-wow-delay=".6s"
                    >
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>*/}
      </div>
      {/* Inner */}
      {/* Controls */}
      <a
        className="carousel-control-prev"
        href="#de-carousel"
        role="button"
        data-mdb-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="sr-only">Forige</span>
      </a>
      <a
        className="carousel-control-next"
        href="#de-carousel"
        role="button"
        data-mdb-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="sr-only">Neste</span>
      </a>
    </section>
  );
}
