export default function Header() {
  return (
    <header className="transparent">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="de-flex sm-pt10">
              <div className="de-flex-col">
                <div className="de-flex-col">
                  {/* logo begin */}
                  <div id="logo">
                    <a href="/">
                      <img alt="" src="logo_2024.png" width={"200px"} />
                    </a>
                  </div>
                  {/* logo close */}
                </div>
                <div className="de-flex-col"></div>
              </div>
              <div className="de-flex-col header-col-mid">
                {/* mainmenu begin */}
                <ul id="mainmenu">
                  {/*<li>
                    <a href="#de-carousel">Home</a>
                    <ul>
                      <li>
                        <a href="index.html">Rockfest</a>
                      </li>
                      <li>
                        <a href="02_djfest-index.html">DJFest</a>
                      </li>
                      <li>
                        <a href="03_electrofest-index.html">Electrofest</a>
                      </li>
                    </ul>
                  </li>*/}
                  <li>
                    <a href="/">Hjem</a>
                  </li>
                  <li>
                    <a href="/news">Nyheter</a>
                  </li>
                </ul>
              </div>
              <div className="de-flex-col">
                <div className="menu_side_area">
                  <a
                    href="https://dmproduksjoner.hoopla.no/event/3495120335"
                    target="_blank"
                    rel="noreferrer"
                    className="btn-main"
                  >
                    <i className="fa fa-sign-in" />
                    <span>Kjøp Billett</span>
                  </a>
                  <span id="menu-btn"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
