import moment from "moment";
import "moment/locale/nb";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
moment.locale("nb");

export default function Blog() {
  const [blogPosts, setBlogPosts] = React.useState();

  const fetchBlogPosts = async () => {
    const res = await fetch(
      process.env.REACT_APP_STRAPI_URL + "/api/newsposts?populate=*"
    );

    const home = await res.json();
    console.log(home);
    setBlogPosts(home);
  };

  React.useEffect(() => {
    fetchBlogPosts();
  }, []);
  return (
    <>
      <div id="wrapper">
        {/*<div id="preloader">
          <div className="preloader1"></div>
        </div>*/}
        <Header />

        <>
          <div className="no-bottom no-top" id="content">
            <div id="top" />
            {/* section begin */}
            <section
              id="subheader"
              className="text-light"
              style={{
                backgroundImage: "url('/images-dj/background/subheader.jpg')",
              }}
              //data-bgimage="url(images-dj/background/subheader.jpg) bottom"
            >
              <div className="center-y relative text-center">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <h1 style={{ maxWidth: "100vh", fontSize: "75px" }}>
                        Nyheter
                      </h1>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
            </section>
            {/* section close */}
            {/* section begin */}
            <section aria-label="section">
              <div className="container">
                <div className="row g-custom-x">
                  {blogPosts?.data?.map((data, index) => {
                    return (
                      <>
                        <div key={index} className="col-lg-4 col-md-6 mb20">
                          <Link to={"/post/" + data?.attributes.slug}>
                            <div className="de-event-item">
                              <div className="d-content">
                                <div className="d-image">
                                  <span className="d-image-wrap">
                                    <img
                                      alt="Post image"
                                      src={
                                        data?.attributes?.headerImage?.data[0]
                                          ?.attributes?.url ||
                                        "images-dj/blog/1.jpg"
                                      }
                                      className="lazy"
                                    />
                                  </span>
                                  <span className="d-date">
                                    <span className="d-mm">
                                      {moment(
                                        data?.attributes.publishedAt
                                      ).format("MMM")}
                                    </span>
                                    <span className="d-dd">
                                      {moment(
                                        data?.attributes.publishedAt
                                      ).format("DD")}
                                    </span>
                                  </span>
                                </div>
                                <div className="d-text">
                                  <a href="02_djfest-blog-single.html">
                                    <h4>{data?.attributes?.title}</h4>
                                  </a>
                                  <p>{data?.attributes?.description}</p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    );
                  })}

                  <div className="spacer-single" />
                  {/*<nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                      <li className="page-item disabled">
                        <a
                          className="page-link"
                          href="#"
                          tabIndex={-1}
                          aria-disabled="true"
                        >
                          Previous
                        </a>
                      </li>
                      <li className="page-item active">
                        <a className="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          Next
                        </a>
                      </li>
                    </ul>
                </nav>*/}
                </div>
              </div>
            </section>
          </div>
          {/* content close */}
          <a href="#" id="back-to-top" />
        </>
        <Footer />
      </div>
    </>
  );
}
