export default function Partners({ data }) {
  const partners = data?.data?.attributes?.partners;

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="wm wow slideInUp"></div>
            <h2 className="wow fadeInUp" data-wow-delay=".2s">
              Samarbeidspartnere:
            </h2>
            <div
              className="small-border bg-color wow zoomIn"
              data-wow-delay=".4s"
            />
          </div>
          <div className="spacer-single" />
        </div>
        <div className="row justify-content-center g-custom-x">
          {partners?.map((data, index) => {
            return (
              <div key={index} className="col-lg-2 col-md-4 col-6 mb-sm-30">
                <a href={data?.link} target="_blank" rel="noreferrer">
                  <img
                    src={data?.logo.data?.attributes?.url}
                    className="img-fluid"
                    alt="Partner Logo"
                  />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
