import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Gallery from "../components/home/Gallery";
import HeadCarousel from "../components/home/HeadCarousel";
import Partners from "../components/home/Partners";
import Schedule from "../components/home/Schedule";

export default function Home() {
  const [homepageData, setHomepageData] = React.useState();

  const fetchHomepageData = async () => {
    const res = await fetch(
      "https://cms-sentrumsliv.sandenutvikling.no/api/homepage?populate[HeaderCarousel][populate]=*&populate[artists][populate]=*&populate[programs][populate]=*&populate[gallery][populate]=*&populate[partners][populate]=*"
    );

    const home = await res.json();
    console.log(home);
    setHomepageData(home);
  };

  React.useEffect(() => {
    fetchHomepageData();
  }, []);

  return (
    <>
      <div id="wrapper">
        {/*<div id="preloader">
          <div className="preloader1" />
        </div>*/}
        {/* header begin */}
        <Header />
        {/* header close */}
        {/* content begin */}
        <div className="no-bottom no-top" id="content">
          <div id="top" />
          {/* Carousel wrapper */}
          <HeadCarousel data={homepageData} />
          {/* Carousel wrapper */}
          <div className="arrow_wrap">
            <div className="arrow__up" />
          </div>
          <section id="section-date" className="bg-color pt40 pb30">
            <div className="container">
              <div className="row g-custom-x align-items-center">
                <div className="col-lg-12">
                  <div className="text-center">
                    <h2
                      className="s2 text-black wow fadeInUp"
                      data-wow-delay="0s"
                    >
                      Lørdag 25 Mai
                    </h2>
                    <h3
                      className="text-black wow fadeInUp"
                      data-wow-delay=".2s"
                    >
                      Rømmetunet, Orkanger
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*<Artists data={homepageData} />*/}
          <Schedule data={homepageData} />
          <section id="section-tickets">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="wm wow slideInUp">Billetter</div>
                  <h2 className="wow fadeInUp" data-wow-delay=".2s">
                    <span className="id-color">Billetter</span>
                  </h2>
                  <div
                    className="small-border bg-color wow zoomIn"
                    data-wow-delay=".4s"
                  />
                </div>
                <div className="spacer-single" />
              </div>
              <div className="row justify-content-center g-custom-x">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="pricing-s1 mb-sm-30 wow flipInY">
                    <div className="top text-center">
                      <h2>ORDINÆR</h2>
                    </div>
                    <div className="mid text-center bg-color-secondary text-black">
                      <p className="price">
                        <span className="currency">NOK</span>
                        <span className="m opt-1">325</span>
                      </p>
                    </div>
                    <div className="bottom">
                      <ul>
                        <li>
                          <i className="icon_check" />
                          Tilgang til hele festivalen
                        </li>

                        {/*<li>
                          <i className="icon_close" />
                          <s>Hotell i nærheten</s>
                        </li>*/}
                      </ul>
                    </div>
                    <div className="action text-center">
                      <a
                        href="https://dmproduksjoner.hoopla.no/event/3495120335"
                        target="_blank"
                        rel="noreferrer"
                        className="btn-main"
                      >
                        Kjøp Billett
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Gallery data={homepageData} />
          <Partners data={homepageData} />
          {/*<section id="section-countdown" aria-label="section">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="wm wow slideInUp">Begin in</div>
                  <div
                    id="defaultCountdown"
                    className="wow fadeInUp"
                    data-wow-delay=".2s"
                  />
                </div>
              </div>
            </div>
          </section>*/}
        </div>
        {/* content close */}
        <a href="#" id="back-to-top" />
        {/* footer begin */}
        <Footer />
        {/* footer close */}
      </div>
    </>
  );
}
