export default function Schedule({ data }) {
  const scheduleData = data?.data?.attributes?.programs?.data;
  return (
    <section
      id="section-schedule"
      aria-label="section-services-tab"
      style={{ backgroundImage: "url('/images-dj/background/1.jpg')" }}
      //data-bgimage="url(https://sentrumsliv.no/images-dj/background/1.jpg)"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              {/*<div className="wm wow slideInUp">Program</div>*/}
              <h2 className="wow fadeInUp" data-wow-delay=".2s">
                <span className="id-color">Program</span>
              </h2>
              <div
                className="small-border bg-color wow zoomIn"
                data-wow-delay=".4s"
              />
            </div>
          </div>
          <div className="spacer-single" />
          <div className="col-md-12">
            <div className="de_tab tab_style_4 text-center">
              {/*<ul className="de_nav de_nav_dark">
                <li className="active" data-link="#section-services-tab">
                  <h3>
                    Day <span>01</span>
                  </h3>
                  <h4>March 20, 2023</h4>
                </li>
                <li data-link="#section-services-tab">
                  <h3>
                    Day <span>02</span>
                  </h3>
                  <h4>March 21, 2023</h4>
                </li>
                <li data-link="#section-services-tab">
                  <h3>
                    Day <span>03</span>
                  </h3>
                  <h4>March 22, 2023</h4>
                </li>
                <li data-link="#section-services-tab">
                  <h3>
                    Day <span>04</span>
                  </h3>
                  <h4>March 23, 2023</h4>
                </li>
                <li data-link="#section-services-tab">
                  <h3>
                    Day <span>05</span>
                  </h3>
                  <h4>March 24, 2023</h4>
                </li>
                </ul>*/}
              <div className="de_tab_content text-left">
                <div id="tab1" className="tab_single_content">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <ul className="list-boxed-s1">
                        {scheduleData?.map((data, index) => {
                          return (
                            <li key={index}>
                              <h3>{data?.attributes?.name}</h3>
                              <span></span>
                            </li>
                          );
                        })}
                      </ul>
                      <br></br>
                      <h5 className="text-white">   
                          {/*Dørene åpner kl 18.00, siste mulighet for å hente billett kl 19.00, konsertslutt kl 23.00*/}
                          Programmet slippes senere
                      </h5>
                    </div>
                  </div>
                </div>
                {/*<div id="tab2" className="tab_single_content">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <ul className="list-boxed-s1">
                        <li>
                          <h3>DJ Fritz</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Taxidermize</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Chevelle</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Lysimachus</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Ovidae</h3>
                          <span />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab_single_content">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <ul className="list-boxed-s1">
                        <li>
                          <h3>DJ Phenomenic</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Rockward</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Floater</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Carabus</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Windgalled</h3>
                          <span />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab_single_content">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <ul className="list-boxed-s1">
                        <li>
                          <h3>DJ Neurogenic</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Cassinese</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Barnard</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Aggrieved</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Victuallership</h3>
                          <span />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab_single_content">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <ul className="list-boxed-s1">
                        <li>
                          <h3>DJ Phenomenic</h3>
                          <span />
                        </li>
                        <li>
                          <h3>DJ Fritz</h3>
                          <span />
                        </li>
                        <li>
                          <h3>DJ Neurogenic</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Emarcid</h3>
                          <span />
                        </li>
                        <li>
                          <h3>Stylewort</h3>
                          <span />
                        </li>
                      </ul>
                    </div>
                  </div>
            </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
