export default function Gallery({ data }) {
  const galleryData = data?.data?.attributes?.gallery?.data;

  return (
    <section
      id="section-gallery"
      style={{ backgroundImage: "url('/images-dj/background/2.jpg')" }}
      //data-bgimage="url(/images-dj/background/2.jpg)"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            {/*<div className="wm wow slideInUp">Gallery</div>*/}
            <h2 className="wow fadeInUp" data-wow-delay=".2s">
              <span className="id-color">Bilder</span>
            </h2>
            <div
              className="small-border bg-color wow zoomIn"
              data-wow-delay=".4s"
            />
          </div>
          <div className="spacer-single" />
        </div>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div
              id="carouselExampleIndicators"
              className="carousel slide carousel-fade wow flipInY"
              data-mdb-ride="carousel"
            >
              {/* Slides */}
              <div className="carousel-inner mb-5">
                {galleryData?.map((data, index) => {
                  let classname;
                  if (index === 0) {
                    classname = "carousel-item active";
                  } else {
                    classname = "carousel-item";
                  }
                  return (
                    <div className={classname}>
                      <img
                        src={data?.attributes.url}
                        className="d-block w-100"
                        alt=""
                      />
                    </div>
                  );
                })}
              </div>
              {/* Slides */}
              {/* Controls */}
              <button
                className="carousel-control-prev"
                type="button"
                data-mdb-target="#carouselExampleIndicators"
                data-mdb-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Forige</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-mdb-target="#carouselExampleIndicators"
                data-mdb-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Neste</span>
              </button>
              {/* Controls */}
              {/* Thumbnails */}
              <div
                className="carousel-indicators"
                style={{ marginBottom: "-20px" }}
              >
                {galleryData?.map((data, index) => {
                  let classname;
                  let current;
                  if (index === 0) {
                    classname = "carousel-item active";
                    current = "true";
                  } else {
                    classname = "";
                    current = "";
                  }
                  return (
                    <button
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide-to={index}
                      className={classname}
                      aria-current={current}
                      aria-label={"Slide " + index + 1}
                      style={{ width: 100 }}
                    >
                      <img
                        className="d-block w-100 img-fluid"
                        src={data?.attributes.url}
                        alt={"Slide " + index + 1 + " thumbnail"}
                      />
                    </button>
                  );
                })}
              </div>
              {/* Thumbnails */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
