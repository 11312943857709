import React from "react";

export default function Footer() {
  const [blogPosts, setBlogPosts] = React.useState();
  const fetchBlogPosts = async () => {
    const res = await fetch(
      process.env.REACT_APP_STRAPI_URL +
        "/api/newsposts?populate=*&sort[0]=publishedAt%3Adesc&pagination[page]=1&pagination[pageSize]=5"
    );

    const home = await res.json();
    console.log(home);

    setBlogPosts(home.data);
  };

  React.useEffect(() => {
    fetchBlogPosts();
  }, []);
  return (
    <footer
      style={{ backgroundImage: "url('/images-dj/background/3.jpg')" }}
      //data-bgimage="url(/images-dj/background/3.jpg)"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>Info</h5>
              <address className="s1">
                <span>
                  <i className="id-color fa fa-map-marker fa-lg" />
                  Kulturstua Børsen, Orkanger
                </span>
                {/*<span>
                  <i className="id-color fa fa-phone fa-lg" />
                  +1 333 1000 2000
                </span>
                <span>
                  <i className="id-color fa fa-envelope-o fa-lg" />
                  <a href="mailto:contact@example.com">contact@example.com</a>
  </span>*/}
              </address>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>Linker</h5>
              <ul>
                <li>
                  <a href="/news">Nyheter</a>
                </li>
                <li>
                  <a href="#section-tickets">Billett</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>Siste Innlegg</h5>
              <ul>
                {blogPosts?.map((data, index) => {
                  return (
                    <li key={index}>
                      <a href={"/post/" + data.attributes.slug}>
                        {data.attributes.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            {/*<div className="widget">
              <h5>App</h5>
              <p>
                Last ned vår app for å få siste oppdateringer direkte på
                telefonen
              </p>
              {/*<form
                action="blank.php"
                className="row form-dark"
                id="form_subscribe"
                method="post"
                name="form_subscribe"
              >
                <div className="col text-center">
                  <input
                    className="form-control"
                    id="txt_subscribe"
                    name="txt_subscribe"
                    placeholder="enter your email"
                    type="text"
                  />{" "}
                  <a href="#" id="btn-subscribe">
                    <i className="arrow_right bg-color-secondary" />
                  </a>
                  <div className="clearfix" />
                </div>
              </form>
              <div className="spacer-10" />
              <small>Your email is safe with us. We don't spam.</small>
              <div class="footer-block information">
                <div class="social">
                  <a
                    href="https://play.google.com/store/apps/details?id=host.exp.exponent&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    style={{
                      display: "inline-block",
                      overflow: "hidden",
                      borderRadius: "13px",
                      width: "200px",
                      height: "auto",
                    }}
                  >
                    <img
                      alt="Tilgjengelig på Google Play"
                      style={{
                        borderRadius: "13px",
                        width: "200px",
                        height: "auto",
                      }}
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/no_badge_web_generic.png"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/expo-go/id982107779?itsct=apps_box_badge&amp;itscg=30200"
                    style={{
                      display: "inline-block",
                      overflow: "hidden",
                      borderRadius: "13px",
                      width: "200px",
                      height: "83px",
                    }}
                  >
                    <img
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/no-no?size=250x83&amp;releaseDate=1439769600"
                      alt="Download on the App Store"
                      style={{
                        borderRadius: "13px",
                        width: "200px",
                        height: "83px",
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="de-flex">
                <div className="de-flex-col">
                  <img
                    alt=""
                    className="f-logo"
                    src="logo_2024.png"
                    width={"200px"}
                  />

                  <span className="copy">
                    © Copyright 2023 - Sentrumsliv. Levert av{" "}
                    <a
                      href="https://biztek.no"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Biztek
                    </a>
                  </span>
                </div>
                <div className="de-flex-col">
                  <div className="social-icons">
                    <a
                      href="https://www.facebook.com/sentrumsliv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-facebook fa-lg" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
